import React, { useState } from 'react'

const EstateFaqSection = () => {
    const [openIndex, setOpenIndex] = useState(null);


    const handleToggle = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };
    const itemsToShow = [{
        question: "How do I schedule a visit to Gravity Lake Estate?",
        answer: "To schedule a visit, you can contact our sales team via phone or email. Alternatively, you can fill out the visit request form on our website, and our team will get in touch with you to confirm your appointment."
    }, {
        question: "What types of villas are available at Gravity Lake Estate?",
        answer: "Gravity Lake Estate offers luxurious 3 & 4 BHK duplex villas ranging from 2750 to 3500 square feet. Each villa is designed to provide spacious living areas and modern amenities for an elevated lifestyle."
    }, {
        question: "What is the size of the Gravity Lake Estate community?",
        answer: "The Gravity Lake Estate community spans across 30 acres, comprising 400 units. The expansive layout ensures ample space for each villa and the inclusion of numerous modern amenities."
    }, {
        question: "What amenities can residents expect at Gravity Lake Estate?",
        answer: "Residents can indulge in a wide array of luxurious amenities, including a clubhouse, swimming pool, landscaped gardens, jogging tracks, children's play area, fitness center, and 24/7 security. The community is designed to offer an enriching and elevated lifestyle."
    }, {
        question: "What is the process for booking a home at Gravity Lake Estate?",
        answer: "To book a home, you need to fill out the booking form available on our website or visit our sales office. Our sales representatives will guide you through the process, including selecting your preferred unit, completing necessary documentation, and making the initial payment."
    }, {
        question: "How does Gravity Lake Estate incorporate nature into the community?",
        answer: "Gravity Lake Estate includes a natural rejuvenation initiative aimed at preserving and enhancing the natural environment. The community features lush greenery, landscaped gardens, and serene spaces to promote a nature-inclusive living experience."
    }]
    return (
        <>
            <div className='FaqSection-main-parent'>
                <div className='FaqSection-sub-parent'>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="Faq-left">
                                <h1>Frequently Asked Questions</h1>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="Faq-right">
                                <div className="Investfaq-parent">
                                    <div className='container'>
                                        <div className="row">
                                            {itemsToShow.map((item, index) => (
                                                <div key={index} className="col-md-12 p-0">
                                                    <div className="accordion-item">
                                                        <div
                                                            className="accordion-item-title"
                                                            onClick={() => handleToggle(index)}
                                                        >
                                                            <p>{item.question}</p>
                                                            <span className="icon">{openIndex === index ? '-' : '+'}</span>

                                                        </div>
                                                        {openIndex === index && (
                                                            <div className="accordion-item-desc">
                                                                {item.answer}

                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EstateFaqSection
