import React, { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import EstateValidationModal from "./EstateValidationModal";
import { message, Spin } from "antd";

const EstatePropertyBanner = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    phonenumber: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [modalMessage, setModalMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.name.trim()) errors.name = "Name is required";
    if (!formData.email.trim()) errors.email = "Email is required";
    if (!/\S+@\S+\.\S+/.test(formData.email)) errors.email = "Email is invalid";
    if (!formData.subject.trim()) errors.subject = "Subject is required";
    if (!formData.phonenumber.trim())
      errors.phonenumber = "Phone number is required";
    if (!/^\d{10}$/.test(formData.phonenumber))
      errors.phonenumber = "Phone number is invalid";
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formErrors = validateForm();

    if (Object.keys(formErrors).length === 0) {
      message.loading("Please wait! Your response is sending.");
      try {
        const response = await fetch(
          "https://lakeestates.gravityhomes.in/forms/contact.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          }
        );

        const data = await response.json(); // Parse JSON response here

        if (response.ok) {
          // Check if the response status is OK (200-299)
          if (data.status === "success") {
            // Redirect to another page after successful form submission
            window.location.href =
              "https://lakeestates.gravityhomes.in/forms/thankyou.html"; // Replace with your desired redirect URL
          } else {
            // Handle the errors or display the error message from the backend
            console.error("Error:", data.message);
            setModalMessage(
              data.message || "An error occurred. Please try again."
            );
          }
        } else {
          // Handle non-2xx HTTP responses
          console.error("Error:", data.message);
          setModalMessage(
            data.message || "An error occurred. Please try again."
          );
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        setModalMessage("An error occurred. Please try again later.");
      }
    } else {
      setErrors(formErrors);
      setModalMessage("Please correct the errors in the form.");
    }

    setIsLoading(false);
    // setShowModal(true);
  };

  useEffect(() => {
    const currentUrl = window.location.href;
    console.log("currentUrl", currentUrl);
    const urlInput = document.getElementById("currentUrlInput");
    if (urlInput) {
      urlInput.value = currentUrl;
    }
  }, []);

  return (
    <>
      <div className="PropertyBanner-main-parent" id="banner">
        <div className="PropertyBanner-sub-parent relative">
          <div className="Banner-main-image">
            <img src="/estatebaner.jpeg.jpg" alt="" />
            <div
              className="Banner-badge-absolute"
              role="button"
              onClick={showModal}
            >
              <img src="/bannerBadgemain.svg" alt="" />
            </div>
          </div>
        </div>

        <div className="gravityarena-banner">
          <div className="container">
            <div className="row">
              <div className="col-md-7"></div>
              <div className="col-md-4">
                <div class="gravity-contact-form">
                  <div class="gravity-data-button">
                    <button type="button">Book A Site Visit</button>
                  </div>
                  <form
                    onSubmit={handleSubmit}
                  >
                    <div class="Book-A-Site-parent">
                      <input
                        type="text"
                        name="name"
                        placeholder="Name"
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                      />
                      {errors.name && (
                        <p className="error text-warning ps-3">{errors.name}</p>
                      )}
                    </div>
                    <div class="Book-A-Site-parent">
                      <input
                        type="text"
                        id="mobile_code"
                        class="form-control"
                        placeholder="Phone Number"
                        name="phonenumber"
                        value={formData.phonenumber}
                        onChange={handleInputChange}
                        required
                      />
                      {errors.phonenumber && (
                        <p className="error text-warning ps-3">
                          {errors.phonenumber}
                        </p>
                      )}
                    </div>
                    <div class="Book-A-Site-parent">
                      <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                      />
                      {errors.email && (
                        <p className="error text-warning ps-3">
                          {errors.email}
                        </p>
                      )}
                    </div>
                    <div class="Book-A-Site-parent">
                      <input
                        type="text"
                        name="subject"
                        placeholder="City"
                        value={formData.subject}
                        onChange={handleInputChange}
                        required
                      />
                      {errors.subject && (
                        <p className="error text-warning ps-3">
                          {errors.subject}
                        </p>
                      )}
                    </div>
                    <div class="Book-A-Site-parent">
                      <input
                        type="text"
                        name="message"
                        placeholder="Message"
                        value={formData.message}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <input
                      type="hidden"
                      id="currentUrlInput"
                      name="currentUrl"
                    />
                    <div class="Book-A-Site-submit">
                      <button
                        type="submit"
                        disabled={isLoading}
                        className={isLoading ? "loading" : ""}
                      >
                        {isLoading ? (
                          <Spin indicator={<LoadingOutlined spin />} />
                        ) : (
                          "Send Now ➜"
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <EstateValidationModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          handleOk={handleOk}
          handleCancel={handleCancel}
        />
      </div>
    </>
  );
};

export default EstatePropertyBanner;
