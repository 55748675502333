import React, { useState } from 'react';
import { Modal } from 'antd';


const EstateLayouts = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentImage, setCurrentImage] = useState('');
    const [isMainImage, setIsMainImage] = useState(false);

    const handleImageClick = (imageSrc, isMain) => {
        setCurrentImage(imageSrc);
        setIsMainImage(isMain);
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setCurrentImage('');
        setIsMainImage(false);
    };

    return (
        <>
            <div className='Layouts-main-parent' id='Layouts'>
                <div className='AssetsComponent-sub-parent'>
                    <h1>Layouts - </h1>
                    <div className='Layouts-sub-parent'>
                        <h2>Master plan</h2>
                        <div className='LayoutMain-image'>
                            <img className='Main-image-modal' src="/lakesideplan1.webp" alt="" onClick={() => handleImageClick('/lakesideplan1.webp', true)} />
                        </div>
                        <div className="FloorPlans-main-parent">
                            <h2>Floor Plans</h2>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className='FloorPlans-image'>
                                        <img src="/lakesideplan2.webp" alt="" onClick={() => handleImageClick('/lakesideplan2.webp', false)} />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className='FloorPlans-image'>
                                        <img src="/lakesideplan3.webp" alt="" onClick={() => handleImageClick('/lakesideplan3.webp', false)} />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className='FloorPlans-image'>
                                        <img src="/lakesideplan4.webp" alt="" onClick={() => handleImageClick('/lakesideplan4.webp', false)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal 
                visible={isModalVisible} 
                footer={null} 
                onCancel={handleCancel} 
                width={isMainImage ? '65%' : '30%'}
                bodyStyle={{ textAlign: 'center' }}
            >
                <img src={currentImage} alt="" style={{ width: '100%', height: '100%' }} />
            </Modal>
        </>
    )
}

export default EstateLayouts;

