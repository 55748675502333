import React from 'react'
import './Estatehighlights.css'
const EstateHighlights = () => {
  return (
    <>
      <div className='Highlights-main-parent' id='Highlights'>
        <div className='Highlights-sub-parent'>
          <h1>Signature <span className='span-subtext'>Highlights -</span></h1>
        </div>
        <div className='PropertyDetails-Highlights'>
          <div className='d-flex Highlights-borderdcfgbhnj firstrow-parent'>
            <div className='Highlights-bordercircle'>
              <div className='Highlights-image'>
                <img src="/meditation.png" alt="" />
              </div>
              <h6>CALMING</h6>
              <p>Lake View</p>
            </div>
            <div className='Highlights-bordercircle2'>
              <div className='Highlights-image'>
                <img src="/nature.png" alt="" />
              </div>
              <h6>NURTURING</h6>
              <p>COMMUNITY LIFESTYLE</p>
            </div>
            <div className='Highlights-bordercircle'>
              <div className='Highlights-image'>
                <img src="/mindful.png" alt="" />
              </div>
              <h6>MINDFUL</h6>
              <p>PRIVATE COMFORT</p>
            </div>
            <div className='Highlights-bordercircle'>
              <div className='Highlights-image'>
                <img src="/mindful.png" alt="" />
              </div>
              <h6>Architecture</h6>
              <p>Guided by Biophilic</p>
            </div>
          </div>
          <div className='d-flex Highlights-borderdcfgbhnj'>
            <div className='Highlights-bordercircle2'>
              <div className='Highlights-image'>
                <img src="/bricks.png" alt="" />
              </div>
              <h6>Breathable</h6>
              <p>Mud Block Designs</p>
            </div>
            <div className='Highlights-bordercircle'>
              <div className='Highlights-image'>
                <img src="/park.svg" alt="" />
              </div>
              <h6>LUSH</h6>
              <p>1500+ Trees</p>
            </div>
            <div className='Highlights-bordercircle'>
              <div className='Highlights-image'>
                <img src="/park.svg" alt="" />
              </div>
              <h6>Spanning</h6>
              <p>15+ Acres</p>
            </div>
            <div className='Highlights-bordercircle'>
              <div className='Highlights-image'>
                <img src="/park.svg" alt="" />
              </div>
              <h6>Secure</h6>
              <p>Gated Community</p>
            </div>
          </div>

          {/* ---------------------------------------------------------------------- */}
          <div className='d-flex Highlights-borderdcfgbhnj'>
            <div className='Highlights-bordercircle'>
              <div className='Highlights-image'>
                <img src="/groundwater.png" alt="" />
              </div>
              <h6>Ground Water</h6>
              <p>Replenishment</p>
            </div>
            <div className='Highlights-bordercircle'>
              <div className='Highlights-image'>
                <img src="/groundwater.png" alt="" />
              </div>
              <h6>Rain Water</h6>
              <p>Recharge Basins</p>
            </div>
            <div className='Highlights-bordercircle'>
              <div className='Highlights-image'>
                <img src="/groundwater.png" alt="" />
              </div>
              <h6>Verdant Green</h6>
              <p>WalkWays</p>
            </div>
            <div className='Highlights-bordercircle'>
              <div className='Highlights-image'>
                <img src="/groundwater.png" alt="" />
              </div>
              <h6>Designer Modelled</h6>
              <p>Driveways</p>
            </div>
          </div>

          <div className='d-flex Highlights-borderdcfgbhnj'>
            <div className='Highlights-bordercircle'>
              <div className='Highlights-image'>
                <img src="/rain-water.png" alt="" />
              </div>
              <h6>Community</h6>
              <p>Club House</p>
            </div>
            <div className='Highlights-bordercircle2'>
              <div className='Highlights-image'>
                <img src="/walking.png" alt="" />
              </div>
              <h6>Cubble Stone</h6>
              <p>Paved Roads</p>
            </div>
            <div className='Highlights-bordercircle'>
              <div className='Highlights-image'>
                <img src="/driveways.png" alt="" />
              </div>
              <h6>500 sq.ft</h6>
              <p>Terrace Garden</p>
            </div>
          </div>
        </div>
        <div className='scroll-more-parent'>
          <div className='scroll-more'>
            <img src="/left-arrow.png" alt="" />
          </div>
          <span>Scroll For More</span>
        </div>
      </div>
    </>

  )
}

export default EstateHighlights
