import React, { useState } from 'react'
import EstateValidationModal from './EstateValidationModal'

const EstateTabsSection = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (
        <>
            <div className='TabsSection-main-parent'>
                <div className='TabsSection-sub-parent'>
                    <div className="row align-items-end">
                        <div className="col-md-8">
                            <div className='TabsSection-top'>
                                <div className='linediv'>
                                </div>
                                <p>Overview</p>
                            </div>
                            <div className='TabsSection-left'>
                                <h1>Gravity Lake Estate -</h1>
                                <p>A nature-inclusive gated villa community in the heart of North Bangalore’s booming business hub. Closer to the Kempegowda International Airport, closer to convenience. Indulge in an enriching array of luxe modern amenities for elevated lifestyle choices. Brought to you by Gravity Homes, our esteemed project included a natural rejuvenation initiative. Stay Tuned.</p>
                                <div className="row">
                                    <div className="col-2 col-md-3">
                                        <div className='TabsSection-left-sub'>
                                            <h4>30</h4>
                                            <h5>ACRES</h5>
                                        </div>
                                    </div>
                                    <div className="col-2 col-md-3">
                                        <div className="TabsSection-left-sub">
                                            <h4>400</h4>
                                            <h5>UNITS</h5>
                                        </div>

                                    </div>
                                    <div className="col-4 col-md-3">
                                        <div className="TabsSection-left-sub">
                                            <h4>3 & 4 BHK</h4>
                                            <h5>DUPLEX VILLAS</h5>
                                        </div>

                                    </div>
                                    <div className="col-4 col-md-3">
                                        <div className="TabsSection-left-sub">
                                            <h4>2750-3500</h4>
                                            <h5>SQ.FT</h5>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className='TabsSection-right'>
                                <p>If you're hurry, quick call for us</p>
                                <div className='TabsSection-right-sub1' role='button' onClick={showModal}>
                                    <p>Contact Us</p>
                                    <img src="/arrow-up-right.svg" alt="" />
                                </div>
                                <div className='TabsSection-right-sub2'>
                                    <h3>Location Details:</h3>
                                    <p>Rajanukunte, Yelahanka</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <EstateValidationModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                handleOk={handleOk}
                handleCancel={handleCancel}
            />
        </>
    )
}

export default EstateTabsSection

